import React, { useEffect } from 'react';

const ChartPopup = ({ ticker, onClose }) => {
  useEffect(() => {
    if (!ticker) return;

    // Create TradingView widget once component is mounted
    new window.TradingView.widget({
      symbol: ticker,
      width: 400,
      height: 300,
      theme: 'light',
      locale: 'en',
      container_id: 'tradingview-chart',
      toolbar_bg: '#f1f3f6',
      enable_publishing: false,
      allow_symbol_change: true,
    });

  }, [ticker]);

  if (!ticker) return null;

  return (
    <div className="chart-popup">
      <div className="chart-popup-content">
        <span className="close-btn" onClick={onClose}>×</span>
        <div id="tradingview-chart"></div> {/* TradingView widget will load here */}
      </div>
    </div>
  );
};

export default ChartPopup;