import React, { Component } from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

export class TradingView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const ticker = urlParams.get('ticker');
      return <div className="PatternsComp">
      <div className="Patterns">
          <h1>Chart Patterns</h1>
        <div className="PatternsData">
            <h1>Chart Patterns</h1>
            <TradingViewWidget
            symbol={ticker}
            theme={Themes.LIGHT}
            locale="en"
            interval="D"
            />
        </div>
      </div>
      </div>
      
      
  }
}