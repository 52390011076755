import logo from './logo.svg';
import React, { Component } from 'react';
import './App.css';

import { Donate } from './Components/Donate'
import { Market } from './Components/Market'
import { MarketDirection } from './Components/MarketDirection'
import { Comparison } from './Components/Comparison'
import { Patterns } from './Components/Patterns'
import { TradingView } from './Components/TradingView'
import { Rules } from './Components/Rules'
import { Books } from './Components/Books'
import { Gamma } from './Components/Gamma'
import { GammaScan } from './Components/GammaScan'
import { Bucket } from './Components/Bucket'
// import { GammaEdge } from './Components/GammaEdge'

import { Notifier } from './Components/Notifier'
import { Route, Switch } from 'react-router-dom';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notify_function: function(content) {},
      rules: {}
    };

    this.changeNotifyFunction = function(new_notify_function) {
      this.setState({notify_function: new_notify_function})
    }.bind(this);

    this.notifyRule = function(rule_name) {
      if(rule_name in this.state.rules) {
        this.state.notify_function(
          <div>
            <h3>{rule_name}: {this.state.rules[rule_name]["tier"]} Tier</h3>

            <div className="NotifierContent">
              <p>{this.state.rules[rule_name]["summary"]}</p>
            </div>
          </div>
        )
      } else {
        this.state.notify_function(
          <div>
            <h3>{rule_name}</h3>

            <div className="NotifierContent">
              <p>Rule not documented</p>
            </div>
          </div>
        )
      }
    }.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
 
    fetch("http://Apiprod2-env.eba-44zy3tfi.us-east-2.elasticbeanstalk.com/api/rules")
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(res => this.setState({rules: res}))
      .catch(error => this.setState({ error }));
  }

  render() {
    return (        
      <>      
            <Switch>    
              <Route path='/donate' component={Donate}/>   
               {/* <Route path='/gammaedge' component={GammaEdge}/>           */}
               <Route path='/marketdirection' component={MarketDirection}/>  
               <Route path='/comparison' component={Comparison}/>  
               <Route path='/patterns' component={Patterns}/> 
               <Route path='/trading_view' component={TradingView}/> 
               <Route path='/rules' component={Rules}/> 
               <Route path='/books' component={Books}/> 
               <Route path='/gamma' component={Gamma}/>
               <Route path='/gamma_scan' component={GammaScan}/>
               <Route path='/bucket' component={Bucket}/>   
               <Route path='/' component={Market}/>  
            </Switch>
            <h7>The above references an opinion and is for information purposes only. It is not intended to be investment advice. Seek a duly licensed professional for investment advice.</h7>

      </>
  );
  }
}

export default App;
