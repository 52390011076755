import React, { Component } from 'react';

export class Books extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    
      return <div className="BooksComp">
      <div className="Books">
          <h1>Recommended Books</h1>
        <div className="BooksData">
            <h1>Recommended Books</h1>
            <h3>Must Reads!</h3>
            <iframe style={{width: "120px", height: "240px"}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=jonlwowski012-20&marketplace=amazon&amp;region=US&placement=0071614133&asins=0071614133&linkId=4d45c20737f46640bec591d8ea404988&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff'></iframe>
            <iframe style={{width: "120px", height: "240px"}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=jonlwowski012-20&marketplace=amazon&amp;region=US&placement=0071807225&asins=0071807225&linkId=f21b26c47e18bdcee2d9967cb8d049d2&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
            <iframe style={{width: "120px", height: "240px"}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=jonlwowski012-20&marketplace=amazon&amp;region=US&placement=0996307931&asins=0996307931&linkId=31a7bf45071e53649266f6807acc2cc8&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>            
            <iframe style={{width: "120px", height: "240px"}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=jonlwowski012-20&marketplace=amazon&amp;region=US&placement=1614271690&asins=1614271690&linkId=91c71589a1bc45fac45582abd53a2760&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
        </div>
      </div>
      </div>
      
      
  }
}