import React, { Component } from 'react';
import { TableData } from './TableData';
import { ApplyFilter } from './SearchOptions';

export class Gamma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gamma_columns: [],
      gamma_data: [[]],
      search_options: {}
    };

    this.setSearchOptions = function(new_search_options) {
      this.setState({search_options: new_search_options})
    }.bind(this);
  }

  modifyData = function(value) {
    if(value.toString() === "true") {
      return  <th style={{background: "green"}}>True</th>
    } else if(value.toString() === "false") {
      return <th style={{background: "red"}}>False</th>
    } else {
      return <th>{value.toString()}</th>
    }
  }

    
  componentDidMount() {
    this.setState({ isLoading: true });
    fetch("http://Apiprod2-env.eba-44zy3tfi.us-east-2.elasticbeanstalk.com/api/gamma")
      .then(response => {
        if (response.ok) {
          console.log(response)
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(res => this.setState({ gamma_columns: res.gamma_columns, gamma_data: res.gamma_data, filtered_data: res.gamma_data, gamma_date: res.gamma_date}), res=>console.log(res))
      .catch(error => this.setState({ error }));
  }



  render() {
    console.log("GAMMA")
    console.log(this.state.gamma_columns)
    return <>
      <div className="GammaComp">
      <h1>Gamma Screener</h1>
        <div className="Gamma">
        <h1>Gamma Screener</h1>
        <h2>Updated: {this.state.gamma_date} UTC</h2>
        <h6>Click on row to open TradingView Chart</h6>
          <div className="GammaData" style={{overflow: "scroll"}}>
            <TableData modifyData={this.modifyData} columns={this.state.gamma_columns} notifyRule={this.props.notifyRule} data={this.state.gamma_data.filter((data_row) => ApplyFilter(this.state.gamma_columns, data_row, this.state.search_options))}/>
          </div>
        </div>
      </div>
    </>
  }
}