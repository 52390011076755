import React from "react";

function Footer() {
  return (
    <div class="alignleft">
    <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=13&l=ez&f=ifr&linkID=42a403c3157ef9238c551dfef940a529&t=jonlwowski012-20&tracking_id=jonlwowski012-20" width="468" height="60" scrolling="no" border="0" marginwidth="0" frameborder="0"></iframe>
    </div>
  );
}

export default Footer;