import React, { Component } from 'react';

export class Donate extends Component {

  render() {

    return <div className="DonateComp">
        <h1>Please Donate to Keep This Website Active!</h1>
        <div className="Donate">
          <h1>Please Donate to Keep This Website Active!</h1>
          <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="business" value="EAL2W358H6JKG" />
            <input type="hidden" name="item_name" value="Stock Screener" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="100" height="100" />
        </form>
        </div>
    </div>
  }
}