import React, { Component } from 'react';

export class Rules extends Component {
  constructor(props) {
    super(props);
  }



  render() {

    return <div className="RulesComp">
      <h1>Screener Rules</h1>
      <h1>Screener Rules</h1>
      <h2>Market Direction</h2>
    
      <h3>21d MA greater than 50d MA Rule</h3>
      <h5>Summary: For Index to pass SMA21 > SMA50 <br/> </h5>
    
      <h3>50 Day MA Slope Postive Rule</h3>
      <h5>Summary: For Index to pass the 50d SMA current day has to be greater than the previous day for all of the last 21 days <br/> </h5>
      <br/>
      
      <hr/>

      <h2>Stock Screener Scoring</h2>
    
      <h3>N-Value Rating</h3>
      <h5>To calculate this score, each rule below is given a n-value from 1-8. Then if the stock passes that rule it receives a score of 2^n.</h5>
    
      <h3>Bucket Rating</h3>
      <h5>To calculate this score, the percentage difference between the actual rule's value and the passing threshold value is calculated. Then the percent difference is multiplied by the n-value score above. </h5>
      <br/>
    
      <hr/>
    
      <h2>Stock Screener Rules</h2>
    
      <h3>50d MA greater than 150d MA Rule</h3>
      <h5>Summary: For stocks to pass SMA50 > SMA150 <br/>
          N-Value: 12</h5>
    
      <h3>150d MA greater than 200d MA Rule</h3>
      <h5>Summary: For stocks to pass SMA150 > SMA200 <br/>
          N-Value: 11</h5>
    
      <h3>52 week high low span Rule</h3>
      <h5>Summary: For stocks to pass 0.75*week52_high > 1.25*week52_low <br/>
          N-Value: 10</h5>
    
      <h3>Relative Strength Greater Than 1.0</h3>
      <h5>Summary: For stocks to pass the EMA60 of ((curr_price-prev_price)/prev_price) / ((curr_price_s&p500-prev_price_s&p500)/prev_price_s&p500) has to be greater than 1.0 <br/>
          N-Value: 9</h5>
    
      <h3>Liquidity Rule</h3>
      <h5>Summary: For stocks to pass SMA50_value*SMA50_volume >= 20 million <br/>
          N-Value: 8</h5>
    
      <h3>Close above 52 week high - 25% Rule</h3>
      <h5>Summary: For stocks to pass prev_close > 0.75*week52_high <br/>
          N-Value: 7</h5>  
    
      <h3>Previous Close Greater Than $10</h3>
      <h5>Summary: To pass the previous close of the stock has to be greater than $10. <br/>
          N-Value: 6 </h5>
    
      <h3>Positive 200d SMA Rule</h3>
      <h5>Summary: For stocks to pass the 200d SMA current day has to be greater than the previous day for all of the last 21 days <br/>
          N-Value: 5</h5>
    
      <h3>Institutional Ownership Rule</h3>
      <h5>Summary: For stocks to pass 5% {'<='} inst_own<br/>
          N-Value: 4</h5>
    
      <h3>Close above 50d MA Rule</h3>
      <h5>Summary: For stocks to pass prev_close > SMA50 <br/>
          N-Value: 3</h5>
    
      <h3>Sales QoQ Yearly Rule</h3>
      <h5>Summary: For stocks to pass Sales QoQ Yearly > 25% <br/>
          N-Value: 2</h5>
    
      <h3>EPS QoQ Yearly Rule</h3>
      <h5>Summary: For stocks to pass EPS QoQ Yearly > 18% <br/>
          N-Value: 1</h5>
    </div>
  }
}