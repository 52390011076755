import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';

const Navigation = () => {
    return(
        <>
            <Navbar collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
                    <Navbar.Brand href="/">CANSLIM Stock Screener</Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav>
                            <Nav.Link href='/'>Home</Nav.Link>
                            <Nav.Link href='/marketdirection'>Market Direction</Nav.Link>
                            <Nav.Link href='/comparison'>Comparison</Nav.Link>
                            <Nav.Link href='/patterns'>Patterns</Nav.Link>
                            <Nav.Link href='/trading_view'>Trading View Charts</Nav.Link>
                            <Nav.Link href='/rules'>Screener Rules</Nav.Link>
                            <Nav.Link href='/gammaedge'>GammaEdge</Nav.Link>
                            <Nav.Link href='/books'>Recommended Books</Nav.Link>
                            <Nav.Link href='/donate'>Donate Now!</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Navigation;