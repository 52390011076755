import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import ChartPopup from './ChartPopup';  // Import the ChartPopup component
import './ChartPopup.css'; // Add styles for the popup modal

function cellFormatter(cell, row, setTicker) {
  return (
    <span
      onMouseEnter={() => setTicker(row.Ticker)}
      onMouseLeave={() => setTicker(null)}
    >
      {cell.toString()}
    </span>
  );
}

export class TableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverTicker: null,
    };
  }

  render() {
    const { hoverTicker } = this.state;

    var column_names = this.props.columns;
    if (column_names.length < 1) {
      return <div>No Results</div>;
    }

    var columns = [];
    column_names.forEach((item) => {
      columns.push({
        dataField: item,
        text: item,
        sort: true,
        formatter: (cell, row) => cellFormatter(cell, row, (ticker) => this.setState({ hoverTicker: ticker })),
        filter: numberFilter(),
      });
    });

    var data = this.props.data;

    if (data.length < 1) {
      return <div>No Results</div>;
    }

    var data_formatted = [];
    data.forEach(function (row) {
      var temp_row = {};
      row.forEach(function (item, idx) {
        temp_row[columns[idx].text] = item;
      });
      data_formatted.push(temp_row);
    });

    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;

    return (
      <div>
        <ToolkitProvider
          keyField="Ticker"
          data={data_formatted}
          columns={columns}
          search
          exportCSV={{ exportAll: false, onlyExportFiltered: true }}
        >
          {(props) => (
            <div>
              <h5>Search for Ticker:</h5>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                keyField="Ticker"
                data={data_formatted}
                columns={columns}
                pagination={paginationFactory()}
                filter={filterFactory()}
                headerClasses="header-class"
                striped
                hover
                condensed
                {...props.baseProps}
              />
              <hr />
              <ExportCSVButton
                { ...props.csvProps }
                style={{ backgroundColor: 'blue', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
              >
                Export CSV
              </ExportCSVButton>
            </div>
          )}
        </ToolkitProvider>

        {/* Show chart popup if hovering over a ticker */}
        {hoverTicker && <ChartPopup ticker={hoverTicker} onClose={() => this.setState({ hoverTicker: null })} />}
      </div>
    );
  }
}
