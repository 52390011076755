import React, { Component } from 'react';
import { TableData } from './TableData';
import { ApplyFilter } from './SearchOptions';

export class Bucket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucket_columns: [],
      bucket_data: [[]],
      search_options: {}
    };

    this.setSearchOptions = function(new_search_options) {
      this.setState({search_options: new_search_options})
    }.bind(this);
  }

  modifyData = function(value) {
    if(value.toString() === "true") {
      return  <th style={{background: "green"}}>True</th>
    } else if(value.toString() === "false") {
      return <th style={{background: "red"}}>False</th>
    } else {
      return <th>{value.toString()}</th>
    }
  }

    
  componentDidMount() {
    this.setState({ isLoading: true });
    fetch("http://Apiprod2-env.eba-44zy3tfi.us-east-2.elasticbeanstalk.com/api/bucket")
      .then(response => {
        if (response.ok) {
          console.log(response)
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(res => this.setState({ bucket_columns: res.bucket_columns, bucket_data: res.bucket_data, filtered_data: res.bucket_data, bucket_date: res.bucket_date}), res=>console.log(res))
      .catch(error => this.setState({ error }));
  }



  render() {
    console.log("bucket")
    console.log(this.state.bucket_columns)
    return <>
      <div className="BucketComp">
      <h1>Bucket Screener</h1>
        <div className="Bucket">
        <h1>Bucket Screener</h1>
        <h2>Updated: {this.state.bucket_date} UTC</h2>
        <h6>Click on row to open TradingView Chart</h6>
          <div className="BucketData" style={{overflow: "scroll"}}>
            <TableData modifyData={this.modifyData} columns={this.state.bucket_columns} notifyRule={this.props.notifyRule} data={this.state.bucket_data.filter((data_row) => ApplyFilter(this.state.bucket_columns, data_row, this.state.search_options))}/>
          </div>
        </div>
      </div>
    </>
  }
}